import Script from 'next/script'

export const ParityDeals: React.FC = () => {
  return (
    <Script
      id='parity-deals'
      strategy='lazyOnload'
      src='https://cdn.paritydeals.com/banner.js'
    />
  )
}
