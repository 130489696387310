// React
import { useEffect, useRef } from 'react'

// Next
import { NextPage } from 'next'
import { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { appWithTranslation } from 'next-i18next'

// Components
import ErrorBoundary from '@/components/errors/error-boundary'
import GoogleAnalytics from '@/components/scripts/google-analytics'
import { ParityDeals } from '@/components/scripts/parity-deals'

const BaseLayout = dynamic(() =>
  import('@/components/layouts/base-layout').then((mod) => mod.BaseLayout)
)
const LandingLayout = dynamic(() =>
  import('@/components/layouts/landing-layout').then((mod) => mod.LandingLayout)
)

// Utils
import { useLayout } from '@/utils/ui/landing/layout'
import { UserContextProvider } from '@/utils/auth/user-context'

// Styles
import '@/styles/global.css'
import '@radix-ui/themes/styles.css'

// External
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { Toaster } from 'react-hot-toast'
import { Theme } from '@radix-ui/themes'

const layouts = {
  main: BaseLayout,
  landing: LandingLayout,
}

const App: NextPage<AppProps> = ({ Component, pageProps }: AppProps) => {
  const layoutType = useLayout()
  const Layout = layouts[layoutType]

  const router = useRouter()
  const oldUrlRef = useRef('')

  useEffect(() => {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
      api_host: '/ingest',
      ui_host: 'https://us.posthog.com',

      // Enable debug mode in development
      loaded: (posthog) => {
        if (process.env.NODE_ENV === 'development') posthog.debug()
      },
    })

    const handleRouteChange = () => posthog?.capture('$pageview')

    const handleRouteChangeStart = () =>
      posthog?.capture('$pageleave', {
        $current_url: oldUrlRef.current,
        $supabase_url: process.env.NEXT_PUBLIC_SUPABASE_URL,
      })

    router.events.on('routeChangeComplete', handleRouteChange)
    router.events.on('routeChangeStart', handleRouteChangeStart)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
      router.events.off('routeChangeStart', handleRouteChangeStart)
    }
  })

  return (
    <Theme
      appearance='light'
      accentColor='violet'
      grayColor='sand'
      scaling='95%'
    >
      <PostHogProvider client={posthog}>
        <UserContextProvider>
          <ErrorBoundary>
            <Layout>
              <GoogleAnalytics />
              <ParityDeals />
              <Component {...pageProps} />
              <Toaster
                position='bottom-center'
                reverseOrder={false}
                toastOptions={{
                  duration: 5000,
                }}
              />
            </Layout>
          </ErrorBoundary>
        </UserContextProvider>
      </PostHogProvider>
    </Theme>
  )
}

export default appWithTranslation(App)
